<template>
  <div class="box">
    <div class="zhu_zhaung_tu_box">
      <v-chart :options="options" autoresize/>
    </div>
    <span class="unit">单位: {{ unit }}</span>
  </div>
</template>

<script>

  export default {
    name: "bingTu",
    components: {},
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      ispreview: Boolean
    },
    data() {
      return {
        unit: '',
        options: {
          title: {
            text: "",
            left: "40px",
            top: '0',
            show: true,
            textStyle: {
              color: "#fff"
            }
          },
          // 提示
          tooltip: {
            trigger: "item",
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: 16
            }
          },
          legend: {
            orient: "vertical",
            left: "10px",
            top: '12%',
            show: false,
            textStyle: {
              color: "#fff"
            }
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              data: [],
              label: {
                normal: {
                  show: true,
                  fontSize: 16,
                  formatter:function(params) {
                    return params.data.name + '' + params.data.value + ' ' + params.data.unit
                  }
                }
              },
              itemStyle: {
                decal: {
                  symbol: "none",
                },
                normal: {
                  color: function(params) {
                    var colorList = [
                      "#4bbee3",
                      "#2BCB95",
                      "#31fd2a",
                      "#c05bdd",
                      "#f29e3c",
                      "#7a65f2",
                      "#65d186",
                      "#FF3B30"
                    ]; //每根柱子的颜色
                    return colorList[params.dataIndex];
                  },
                  shadowColor: "rgba(0,160,221,1)",
                  //模糊细数
                  shadowBlur: 4
                }
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            }
          ]
        },
        optionsStyle: {}, // 样式
        optionsData: {}, // 数据
        optionsSetup: {},
        flagInter: null
      };
    },
    watch: {
      value(val) {
        if (val.title == '人口统计') {
          this.options.title.show = false
        } else {
          this.options.title.text = val.title
        }

        this.options.series[0].data = val.data
        this.unit = val.unit
      }
    },
    mounted() {

    },
    methods: {
      
    }
  };
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;
  position: relative;
  // background-color: #212121;
}

.zhu_zhaung_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
  box-sizing: border-box;
  // background-color: #31fd2a;
}
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.unit { position: absolute; left: 10px; bottom: 0px; color: #82fefe; font-size: 14px; }
</style>
