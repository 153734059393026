import { render, staticRenderFns } from "./zhu-zhuang-tu.vue?vue&type=template&id=1ddb08be&scoped=true&"
import script from "./zhu-zhuang-tu.vue?vue&type=script&lang=js&"
export * from "./zhu-zhuang-tu.vue?vue&type=script&lang=js&"
import style0 from "./zhu-zhuang-tu.vue?vue&type=style&index=0&id=1ddb08be&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ddb08be",
  null
  
)

export default component.exports