<template>
  <div class="box">
    <div class="zhu_zhaung_tu_box">
      <v-chart :options="options" autoresize />
    </div>
    <span class="unit">单位: {{ unit }}</span>
  </div>
</template>

<script>
export default {
  name: "LeiDaTu",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    ispreview: Boolean,
  },
  data() {
    return {
      unit: "",
      options: {
        title: {
          text: "Basic Radar Chart",
        },
        legend: {
          data: ["Allocated Budget", "Actual Spending"],
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "Sales", max: 6500 },
            { name: "Administration", max: 16000 },
            { name: "Information Technology", max: 30000 },
            { name: "Customer Support", max: 38000 },
            { name: "Development", max: 52000 },
            { name: "Marketing", max: 25000 },
          ],
        },
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            data: [
              {
                value: [4200, 3000, 20000, 35000, 50000, 18000],
                name: "Allocated Budget",
              },
              {
                value: [5000, 14000, 28000, 26000, 42000, 21000],
                name: "Actual Spending",
              },
            ],
          },
        ],
      },
      optionsStyle: {}, // 样式
      optionsData: {}, // 数据
      optionsSetup: {},
      flagInter: null,
    };
  },
  watch: {
    value(val) {
      if (val.title == "人口统计") {
        this.options.title.show = false;
      } else {
        this.options.title.text = val.title;
      }

      this.options.series[0].data = val.data;
      this.unit = val.unit;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 90%;
  position: relative;
  // background-color: #212121;
}

.zhu_zhaung_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
  box-sizing: border-box;
  // background-color: #31fd2a;
}
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.unit {
  position: absolute;
  left: 10px;
  bottom: 0px;
  color: #fff;
  font-size: 14px;
}
</style>
