<template>
  <div class="box" style="width: 100%; height: 100%; padding: 10px;"> 
    <iframe 
      class="full" 
      src="home-map.html" 
      frameborder="0" 
      style="width: 100%; height: 100%;" 
      ref="unity">
    </iframe>
  </div>
</template>

<script>
  export default {
    name: 'Map',
    data() {
      return {
        zoom: 12,
        center: [118.671389, 43.521016],
        map: null
      }
    },
    created () {
      
    },
    mounted() {
    },
    methods: {
      
    }
  }
</script>

<style lang="less" scoped>
  .box {
    width: 100%;
    height: 100%;
  }
</style>