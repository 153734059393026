<template>
  <div class="box">
    <div class="zhu_zhuang_tu_box">
      <v-chart :options="options" autoresize/>
    </div>

    <!-- <span class="unit">单位: {{ unit }}</span> -->
  </div>
</template>

<script>
export default {
  name: "page2",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    ispreview: Boolean
  },
  data() {
    return {
      options: {
        title: {
          show: false,
          top: '0%',
          left: '20px',
          text: '1111'
        },
        legend: {
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 14
            },
            rotate: -45
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          data: [],
          name: '',
          splitLine: {
            show: false
          },
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: "#fff",
              fontSize: 10
            }
          }
        },
        series: [
          {
            data: [],
            type: "bar",
            barGap: "0%",
            barWidth: 25,
            color: '#2366fc',

            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = [
                    // "#65d186",
                    // "#2BCB95",
                    // "#F6BD16",
                    // "#c05bdd",
                    // "#f29e3c",
                    // "#7a65f2",
                    // "#65d186",
                    // "#FF3B30",

                    "#74fafd",
                    "#74fafd",
                    "#74fafd",
                    "#74fafd",
                    "#74fafd",
                    "#74fafd",
                    "#74fafd",
   

                  ]; //每根柱子的颜色
                  return colorList[params.dataIndex];
                },
                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                //   [
                //     {
                //       offset: 0,
                //       color: "rgba(0,244,255,1)" // 0% 处的颜色
                //     },
                //     {
                //       offset: 1,
                //       color: "rgba(0,77,167,1)" // 100% 处的颜色
                //     }
                //   ],
                //   false
                // ),
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                shadowBlur: 4
              }
            },
            // 标签
            label: {
              show: true,
              position: "top",
              distance: 10,
              fontSize: 16,
              color: '#fff',
              fontWeight: 500
            }
          }
        ],
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: 16
          }
        }
      },
    };
  },
  watch: {
    value(val) {
      this.options.xAxis.data = val.x
      this.options.yAxis.data = val.y
      this.options.series[0].data = val.y
      this.options.yAxis.name = val.unit
    }
  },
  mounted() {

  },
  methods: {
    
  }
};
</script>

<style scoped lang="scss">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.zhu_zhuang_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // border: 1px solid #f00;
}

.unit { position: absolute; left: 10px; bottom: 5px; color: #fff; font-size: 14px; }

</style>
