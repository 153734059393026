<template>
  <div class="box">
    <div class="zhu_zhuang_tu_box">
      <v-chart :options="options" autoresize/>
    </div>
  </div>
</template>

<script>
export default {
  name: "XianXingTu",
  components: {},
  props: {
    value: Object,
    ispreview: Boolean
  },
  data() {
    return {
      options: {
        grid: {},
        color: ['#1E90FF'],
        title: {
          text: "",
          left: "40px",
          top: '0',
          show: true,
          textStyle: {
            color: "#fff"
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{c}",
          textStyle: {
            color: "rgb(13, 246, 94)"
          }
        },
        legend: {
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 16,
            }
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          },
          // y 轴名称
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
            textStyle: {
              color: "#fff",
              fontSize: 16
            }
          },
          // y 轴数值
          axisLabel: {
            show: false,
            textStyle: {
              color: "#fff",
              fontSize: 14
            }
          },
          inverse: false,
          scale: false,
          splitLine: {
            lineStyle: {
              color:"#1E90FF",
              width:1
            }, 
            show: false
          }
        },
        series: [
          {
            areaStyle: {
              opacity: 0.05,
            },
            data: [],
            lineStyle: {
              width: 3
            },
            type: "line",
            showSymbol: true,
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            // 标签
            label: {
              show: true,
              position: "top",
              distance: 10,
              fontSize: 14,
              color: '#fff',
              fontWeight: 500
            }
          }
        ],
      }
    };
  },
  computed: {
    
  },
  watch: {
    value: {
      handler(val) {
        this.options.xAxis.data = val.x
        this.options.yAxis.data = val.y
        this.options.series[0].data = val.y
        this.options.yAxis.name = val.unit
      },
      deep: true
    }
  },
  created() {

  },
  methods: {
    
  }
};
</script>

<style scoped lang="scss">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.zhu_zhuang_tu_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // padding: 0 10px;
  box-sizing: border-box;
}
</style>
